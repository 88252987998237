<template>
  <div
    class="container"
    :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
  >
    <div>
      <v-card class="shadow card-success" v-if="showSuccess">
        <div>
          <v-icon class="text-blue" size="80" icon="mdi-check-circle"></v-icon>
        </div>
        <div style="font-size: 28px; color: green">
          {{ $t("message.bookingSuccessful") }}
        </div>
        <div>{{ $t("message.thankYou") }}</div>
      </v-card>
    </div>
    <div class="page-divider" v-if="!showSuccess">
      <div class="card1-container">
        <div>
          <div class="container-header">
            <div class="typing" :dir="direction">
              <span class="typing-header">{{
                $t("message.bookingDetails")
              }}</span>
            </div>
          </div>
          <v-card class="shadow card1">
            <div class="card-details">
              <div>
                <div>
                  <span style="font-size: 20px">{{ hotelsData.title }}</span>
                </div>
              </div>
              <div class="left-side-header">
                <div>
                  <v-icon
                    class="text-orange"
                    v-for="(item, index) in hotelsData.stars"
                    :key="index"
                    size="14"
                    icon="mdi-star"
                  ></v-icon>
                  <span class="rating-count">{{ hotelsData.stars }}/5</span>
                </div>
              </div>
            </div>
            <div
              class="card-container shadow bg-gray-100"
              v-for="(item, index) in hotelsData.room"
              :key="index"
            >
              <div
                class="image-container"
                v-if="room_select.find((i) => i.index === index)"
              >
                <img
                  v-if="!item.image_url"
                  src="@/assets/imgs/img.jpg"
                  alt=""
                />
                <img v-if="item.image_url" :src="item.image_url" alt="" />
              </div>
              <div
                class="card-text"
                v-if="room_select.find((i) => i.index === index)"
              >
                <h1 :dir="direction" style="font-size: large; font-weight: 700">
                  {{ item.name }}
                </h1>
                <div>
                  <span style="color: #888" class="mx-1"
                    ><v-icon
                      size="18"
                      class="text-#888"
                      icon="mdi-bed-queen-outline"
                    ></v-icon>
                    <span class="mx-1"> 
                      {{ room_select.find(i => i.index === index).room_numbers }}
                      غرفة 
                    </span>
                  </span>
                  <span style="color: #888"
                    ><v-icon
                      size="18"
                      class="text-#888 mx-1"
                      icon="mdi-account-multiple-outline"
                    ></v-icon
                    >{{ $t("message.roomFor") }} {{ item.adult_number }}
                    {{ $t("message.persons") }}</span
                  >
                </div>
                <div class="price-details">
                  <span dir="ltr"
                    >{{ (item.total_price_with_currency * room_select.find(i => i.index === index).room_numbers).toLocaleString() }}
                    {{ $store.state.users.currency_selected }}</span
                  >
                  <span>{{ $t("message.finalPrice") }}</span>
                </div>
              </div>
            </div>
            <div class="checkin-show shadow bg-gray-100">
              <div class="card-text">
                <h1 :dir="direction" style="font-size: 14px; font-weight: 700">
                  {{ $t("message.arrivalDate") }}
                </h1>
                <div class="price-details">
                  <span v-if="checkin"> {{ checkin }}</span>
                  <span v-if="!checkin"> {{ $t("message.notAvailable") }}</span>
                </div>
              </div>
              <div style="color: #4347b6">
                <v-icon
                  size="40"
                  class="text-#888 ml-1"
                  icon="mdi-swap-horizontal"
                ></v-icon>
              </div>
              <div class="card-text">
                <h1 :dir="direction" style="font-size: 14px; font-weight: 700">
                  {{ $t("message.departureDate") }}
                </h1>
                <div class="price-details">
                  <span v-if="checkout"> {{ checkout }} </span>
                  <span v-if="!checkout">
                    {{ $t("message.notAvailable") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="stay-show shadow bg-gray-100">
              <div class="card-text">
                <h1 :dir="direction" style="font-size: 14px; font-weight: 700">
                  {{ $t("message.totalStayDuration") }}
                </h1>
                <div class="price-details" v-if="!checkin">
                  <span>{{ $t("message.notSpecified") }}</span>
                </div>
                <div class="price-details" v-if="checkin && checkout">
                  <span
                    ><span class="mx-1">{{ daysPeriod }}</span
                    >{{ $t("message.days") }}</span
                  >
                </div>
              </div>
              <div class="card-text">
                <h1 :dir="direction" style="font-size: 14px; font-weight: 700">
                  {{ $t("message.youSelected") }}
                </h1>
                <div class="price-details">
                  <span
                    >{{ $t("message.roomFor") }} {{ adult_no }}
                    {{ $t("message.adults") }}</span
                  >
                </div>
              </div>
            </div>
            <div v-if="hotelsData.room" class="price-show shadow bg-gray-100">
              <div class="card-text-section">
                <h1 :dir="direction" style="font-size: 15px; font-weight: 500">
                  {{ $t("message.totalCostSummary") }}
                </h1>
                <h1 :dir="direction" style="font-size: 15px; font-weight: 500">
                  {{ $t("message.originalPrice") }}:
                  {{ totalCost?.toLocaleString() }}
                  {{ $store.state.users.currency_selected }}
                </h1>
                <h1 :dir="direction" style="font-size: 15px; font-weight: 500">
                  {{ $t("message.discount") }}: 0
                  {{ $store.state.users.currency_selected }}
                </h1>
                <h1 :dir="direction" style="font-size: 15px; font-weight: 500">
                  {{ $t("message.priceAfterDiscount") }}:
                  {{ totalCost?.toLocaleString() }}
                  {{ $store.state.users.currency_selected }}
                </h1>
              </div>
              <div class="price-divider"></div>
              <div class="input-field-section">
                <h1
                  :dir="direction"
                  style="font-size: 14px; font-weight: 700; color: #999"
                >
                  {{ $t("message.enterDiscountCode") }}
                </h1>
                <div class="input-field">
                  <v-text-field
                    dir="rtl"
                    :placeholder="$t('message.discountCode')"
                    density="compact"
                    label=""
                    variant="outlined"
                  ></v-text-field>
                  <div>
                    <button
                      type="button"
                      class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg px-4 py-2.5 text-sm text-center"
                    >
                      {{ $t("message.apply") }}
                    </button>
                  </div>
                </div>
                <div class="price-details">
                  <span
                    >{{ $t("message.finalPrice") }}:
                    {{ totalCost.toLocaleString() }}
                    {{ $store.state.users.currency_selected }}
                  </span>
                </div>
              </div>
            </div>
          </v-card>
        </div>

        <div>
          <v-card class="mt-1">
            <div class="total-cost-title mx-3">
              {{ $t("message.totalFinalCost") }}
            </div>
            <div class="book-show shadow" v-if="room_select">
              <div class="price-details">
                <span>
                  {{ totalCost.toLocaleString() }}
                  {{ $store.state.users.currency_selected }}</span
                >
                <span>{{ $t("message.finalPrice") }}</span>
              </div>
              <div>
                <button
                  type="button"
                  class="text-white bg-blue-600 hover:bg-blue-800 font-large rounded-full px-6 py-2 text-md text-center"
                  @click="submitBooking()"
                >
                  {{ $t("message.completeBooking") }}
                </button>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const store = useStore(); // Vuex store instance
const hotelsData = ref([]);
const totalCost = ref(0);
const route = useRoute();
const showHotelOpptions = ref(false);
const id = route.query.id;
const checkin = route.query.checkin;
const checkout = route.query.checkout;
const room_no = route.query.room_no;
const room_select = route.query.room_select
  ? JSON.parse(route.query.room_select)
  : [];
const adult_no = route.query.adult_no;
const children_no = route.query.children_no;
const todayDate = ref("");
const tomorrowDate = ref("");
const daysPeriod = ref(0);
const router = useRouter();

const getHotel = async () => {
  try {
    await store
      .dispatch("getHotel", {
        take: 9,
        page: 1,
        id: id,
        checkin: checkin ? checkin : todayDate.value,
        checkout: checkout ? checkout : tomorrowDate.value,
        room_number: room_no,
        adult_number: adult_no,
        children_number: children_no,
        currency: store.getters.currency_selected,
      })
      .then((res) => {
        hotelsData.value = res.data;
        for (let item of room_select) {
          totalCost.value =
            totalCost.value +
            hotelsData.value.room[item.index].total_price_with_currency *
              item.room_numbers;
        }
        showHotelOpptions.value = true;
      });
  } catch (error) {
    console.error("Error fetching hotels:", error);
  }
};

// Fetch hotels data when the component is mounted
onBeforeMount(() => {
  if (checkin && checkout) {
    // Convert the date strings to Date objects
    const checkinDate = new Date(checkin);
    const checkoutDate = new Date(checkout);
    // Calculate the difference in milliseconds
    const timeDifference = checkoutDate - checkinDate;

    // Convert the difference from milliseconds to days
    daysPeriod.value = timeDifference / (1000 * 60 * 60 * 24);
  }
  getHotel();
});
// const activeTab = ref(1);
const showSuccess = ref(false);

const submitBooking = async () => {
  await store
    .dispatch("makeReservation", {
      hotel_id: route.query.id,
      checkin: route.query.checkin,
      checkout: route.query.checkout,
      adults: route.query.adult_no,
      children: route.query.children_no,
      currency: route.query.currency,
      country: "Iraq",
      rooms: room_select.map((rm) => ({
        room_id: hotelsData.value.room[rm.index].id,
        number_of_rooms: rm.room_numbers,
      })),
    })
    .then((res) => {
      router.push({
        name: "makeReservation",
        params: {
          id: res.data.id,
        },
      });
    });
};

const direction = ref("rtl"); // Add direction state
</script>

<style scoped>
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  padding: 10px 10px;
  position: relative;
}
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  margin-top: 1.5rem;
  direction: rtl;
  margin-bottom: 4rem;
}
.rating-count {
  font-size: 12px;
  margin-right: 3px;
}
.start-count {
  background-color: #fafafa;
  padding: 2px 8px;
  align-items: center;
  gap: 1px;
}
.page-divider {
  display: flex;
  gap: 20px;
  width: 100%;
}
.page-divider .card1-container {
  width: 100%;
}
.page-divider .card1-container .card1 {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  gap: 1rem;
  width: 100%;
}
.page-divider .card2 {
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 50%;
  margin-top: 5rem;
}
.page-divider .card2 .blue-item {
  background-color: #003b7d;
  color: #fff;
}
.page-divider .card2 div {
  padding: 1rem;
  cursor: pointer;
}
.left-side-header {
  display: flex;
  justify-content: flex-start;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 175;
  border-radius: 10px;
}
.image-container {
  height: 160px;
  width: 200px;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.card-text {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 1rem;
}
.checkin-show {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 10px;
}
.stay-show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}
.price-show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  gap: 0.5rem;
  border-radius: 10px;
}
.inputs-show {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
  gap: 0.5rem;
  border-radius: 10px;
}
.input-field-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 45%;
}
.input-field {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.input-field-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 40%;
}
.input-field-info-email {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 80%;
}
.card-text-section {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.input-field-section-info {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.book-show {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  align-items: center;
}
.card-success {
  display: flex;
  flex-direction: column;
  padding: 7rem 5rem;
  align-items: center;
  gap: 2rem;
}
.card-details {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin-bottom: 1rem;
}
.price-details {
  display: flex;
  flex-direction: column;
  color: #4347b6;
  font-weight: 700;
  font-size: 13px;
}
.price-divider {
  color: #4347b6;
  border-left: 2px solid #999;
  height: 100%;
}
.total-cost-title {
  color: #ffb325;
  font-weight: 700;
  font-size: 16px;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }
  .page-divider {
    flex-direction: column;
  }
  .page-divider .card1-container {
    width: 100%;
  }
  .page-divider .card2 {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;
  }
  .input-field-section-info {
    flex-direction: column;
    gap: 1rem;
  }
  .card-container {
    flex-direction: column;
    height: auto;
  }
  .checkin-show,
  .stay-show,
  .price-show {
    flex-direction: column;
    align-items: flex-start;
  }
  .card-text {
    margin: 1rem 0;
  }
  .card-text-section {
    width: 100%;
  }
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: 600;
}
</style>
