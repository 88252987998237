<template>
  <div>
    <Header />
    <HamburgerMenu />
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      class="loading-spinner"
    ></v-progress-circular>
    <div v-else>
      <div v-if="showHotelOpptions" :class="{ blur: tokenExists && dialog }">
        <HotelHeader :hotelsData="hotelsData" />
        <HotelUpperSectionShow :hotelsData="hotelsData" />
        <HotelDescription :hotelsData="hotelsData" />
        <HotelRoomsList :hotelsData="hotelsData" />
        <HotelRating :hotelsData="hotelsData" />
      </div>
      <Footer />
      <CopyRightComponent />
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="headline text-center my-2">{{
            validToken ? $t("message.rateHotel") : $t("message.invalidToken")
          }}</v-card-title>
          <v-card-text>
            <div v-if="validToken" class="dialog-content">
              <div class="rating-container">
                <v-rating
                  v-model="rating"
                  :length="5"
                  hover
                  color="orange darken-3"
                  size="x-large"
                ></v-rating>
              </div>
              <v-locale-provider :rtl="locale === 'ar' || locale === 'kr'">
                <v-textarea
                  v-model="comment"
                  :label="$t('message.yourOpinion')"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-locale-provider>
            </div>
            <div v-else class="dialog-content">
              <p>{{ $t("message.alreadyRated") }}</p>
            </div>
          </v-card-text>
          <v-card-actions v-if="validToken" class="justify-center">
            <v-btn class="styled-btn" @click="submitRating">{{
              $t("message.submit")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import HamburgerMenu from "../components/HamburgerMenu.vue";
import HotelHeader from "../components/ShowHotelComponents/HotelHeader.vue";
import HotelUpperSectionShow from "../components/ShowHotelComponents/HotelUpperSectionShow.vue";
import HotelDescription from "../components/ShowHotelComponents/HotelDescription.vue";
import HotelRoomsList from "../components/ShowHotelComponents/HotelRoomsList.vue";
import HotelRating from "../components/ShowHotelComponents/HotelRating.vue";
import Footer from "../components/Footer.vue";
import CopyRightComponent from "../components/CopyRightComponent.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, onBeforeMount, onMounted, watch } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { useI18n } from "vue-i18n";

export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  name: "ShowHotel",
  components: {
    Header,
    HamburgerMenu,
    HotelHeader,
    HotelUpperSectionShow,
    HotelDescription,
    HotelRoomsList,
    HotelRating,
    Footer,
    CopyRightComponent,
  },
  setup() {
    const store = useStore();
    const hotelsData = ref([]);
    const route = useRoute();
    const router = useRouter();
    const { locale, t } = useI18n();
    const showHotelOpptions = ref(false);
    const dialog = ref(false);
    const validToken = ref(false);
    const rating = ref(0);
    const comment = ref("");
    const loading = ref(true);

    // Default parameters
    const defaultGovernorate = "";
    const defaultRoomNo = 1;
    const defaultAdultNo = 1;
    const defaultChildrenNo = 0;

    const id = route.query.id;
    const token = route.query.token;
    const tokenExists = !!token;
    const governorate = route.query.governorate || defaultGovernorate;
    const checkin = route.query.checkin;
    const checkout = route.query.checkout;
    const room_no = route.query.room_no || defaultRoomNo;
    const adult_no = route.query.adult_no || defaultAdultNo;
    const children_no = route.query.children_no || defaultChildrenNo;
    const todayDate = ref("");
    const tomorrowDate = ref("");

    const validateToken = async () => {
      try {
        // Mock response for testing
        let response;
        if (token === "VALID_MOCK_TOKEN_12345") {
          response = { data: { valid: true } };
        } else if (token === "INVALID_MOCK_TOKEN_67890") {
          response = { data: { valid: false } };
        } else {
          response = { data: { valid: false } };
        }

        validToken.value = response.data.valid;
        dialog.value = true;

        if (!validToken.value) {
          setTimeout(() => {
            router.push("/"); // Redirect to the homepage or any other page after 5 seconds
          }, 5000);
        }
      } catch (error) {
        console.error("Error validating token:", error);
        validToken.value = false;
        dialog.value = true;
        setTimeout(() => {
          router.push("/"); // Redirect to the homepage or any other page after 5 seconds
        }, 5000);
      }
    };

    const submitRating = async () => {
      try {
        // Mock response for testing
        // const response = await axios.post('/api/submit-rating', {
        //   token,
        //   rating: rating.value,
        //   hotelId: id,
        //   comment: comment.value,
        // });
        alert(t("message.ratingSubmitted"));
        router.push("/"); // Redirect to the homepage or any other page after submission
      } catch (error) {
        console.error("Error submitting rating:", error);
      }
    };

    const getHotel = async () => {
      try {
        await store
          .dispatch("getHotel", {
            take: 9,
            page: 1,
            id: id,
            governorate: governorate,
            checkin: checkin ? checkin : todayDate.value,
            checkout: checkout ? checkout : tomorrowDate.value,
            room_number: room_no,
            adult_number: adult_no,
            children_number: children_no,
            currency: store.getters.currency_selected,
          })
          .then((res) => {
            hotelsData.value = res.data;
            showHotelOpptions.value = true;
            loading.value = false;
          });
      } catch (error) {
        console.error("Error fetching hotels:", error);
        loading.value = false;
      }
    };

    watch(
      () => route.query,
      () => {
        store
          .dispatch("getHotel", route.query)
          .then((res) => {
            hotelsData.value = res.data;
          })
          .catch((error) => {
            console.error("Error fetching governorates:", error);
          });
      }
    );


    onBeforeMount(() => {
      // Set today's date when the component is mounted
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      todayDate.value = `${year}-${month}-${day}`;

      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1); // Add one day
      const year2 = tomorrow.getFullYear();
      const month2 = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
      const day2 = tomorrow.getDate().toString().padStart(2, "0");
      tomorrowDate.value = `${year2}-${month2}-${day2}`;
      getHotel();
    });

    onMounted(() => {
      if (tokenExists) {
        validateToken();
      }
    });

    const hotelClick = (id) => {
      router.push({
        name: "Hotel",
        query: {
          id: id,
          governorate: governorate,
          checkin: checkin,
          checkout: checkout,
          room_no: room_no,
          adult_no: adult_no,
          children_no: children_no,
        },
      });
    };

    return {
      locale,
      t,
      hotelsData,
      hotelClick,
      showHotelOpptions,
      dialog,
      validToken,
      rating,
      comment,
      loading,
      submitRating,
      tokenExists,
    };
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.blur {
  filter: blur(5px);
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.v-rating {
  width: 100%;
}

.v-textarea {
  width: 100%;
}

.v-card-actions {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.v-btn.styled-btn {
  color: white;
  background-color: #003b7d;
  border-radius: 9999px;
  font-weight: 500;
  /* padding: 12px 24px; */
  padding: 0.25rem 1.5rem 2rem 1.5rem;
  transition: background-color 0.3s ease;
}

.v-btn.styled-btn:hover {
  background-color: #004080;
}

.v-btn.styled-btn:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.5);
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
