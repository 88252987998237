<template>
  <div class="login-container">
    <div class="img-container">
      <img src="@/assets/imgs/landscape.jpg" alt="" />
      <div class="overlay"></div>
      <div class="booking-text-login">Bookingiraq</div>
      <div class="bottom-text">
        اكبر منصة لحجز الفنادق في العراق. من خلال BookingIraq يمكنك حجز الفنادق من
        المدن العراقية المفضلة لديك بكل سهولة واريحية. كل ما عليك فعله هو تحديد الوجهة
        الخاصة بك, عدد الاشخاص ومدة الاقامة ونحن نقوم بالباقي
      </div>
    </div>
    <div class="right-side" dir="rtl" style="margin-top: 4rem">
      <div
        style="font-size: 26px; color: #ffb325; font-weight: bold; margin-bottom: 2rem"
      >
        تسجيل الدخول
      </div>
      <div>
        <v-locale-provider rtl>
          <div class="mb-3">البريد الالكتروني</div>
          <v-text-field
            placeholder="البريد الالكتروني"
            append-inner-icon="mdi-email"
            variant="outlined"
            v-model="postData.email"
          ></v-text-field>
        </v-locale-provider>
      </div>
      <div>
        <v-locale-provider rtl>
          <div class="mb-3">الرمز السري</div>
          <v-text-field
            :prepend-inner-icon="visible ? ' mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            placeholder="الرمز السري"
            append-inner-icon="mdi-lock-outline"
            variant="outlined"
            v-model="postData.password"
            @click:prepend-inner="visible = !visible"
          ></v-text-field>
          <!-- <v-text-field
            placeholder="الرمز السري"
            append-inner-icon="mdi-lock"
            variant="outlined"
            type="password"
          ></v-text-field> -->
        </v-locale-provider>
      </div>
      <div>
        هل نسيت الرمز السري؟
        <router-link
          active-class="active"
          class="hover:text-red duration-500"
          to="/password-recovery"
        >
          <span style="color: #ffb325; cursor: pointer">اعد تعيين الرمز السري</span>
        </router-link>
      </div>
      <div class="show-more-button">
        <!-- <router-link active-class="active" class="hover:text-red duration-500" to="/"> -->
        <button
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
          @click="post"
        >
          تسجيل الدخول
        </button>
        <!-- </router-link> -->
      </div>
      <div class="mt-5 mr-2" style="font-size: 15px">
        أو قم بأنشاء
        <router-link
          active-class="active"
          class="hover:text-red duration-500"
          to="/signup"
        >
          <span style="color: #ffb325; cursor: pointer">حساب جديد</span>
        </router-link>
      </div>
      <div class="mr-2" style="font-size: 15px; margin-top: 4rem">
        أو استخدم إحدى هذه الخيارات:
      </div>
      <div class="sign-in-with">
        <div style="background-color: #fafafa; padding: 0.5rem 1rem; border-radius: 10px">
          <v-icon class="ml-2" size="medium" icon="mdi-google" color="red"></v-icon>
          تسجيل عن طريق جوجل
        </div>
        <div style="background-color: #fafafa; padding: 0.5rem 1rem; border-radius: 10px">
          <v-icon class="ml-2" size="medium" icon="mdi-facebook" color="blue"></v-icon>
          تسجيل عن طريق الفيسبوك
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { toast } from "vue-sonner";
import { useI18n } from "vue-i18n";

export default {
  /* eslint-disable */
  name: "Login",
  components: {},
  setup() {
    const visible = ref(false); // Initializing visible as a reactive ref
    const postData = ref({
      email: "",
      password: "",
    });
    const store = useStore(); // Access the Vuex store
    const router = useRouter();
    const {t} = useI18n()

    const post = async () => {
      try {
        await store.dispatch("login", postData.value)
        router.push("/");
        // Login successful, you can redirect or perform other actions here
      } catch (error) {
        // Handle login error
        toast.error(t("message.login_error"))
      }
    };

    return {
      postData,
      post,
      visible,
    };
  },
};
</script>

<style>
.login-container {
  font-family: "Cairo", sans-serif;

  width: 70%;
  height: 100vh;
  margin: auto;
  display: flex;
  /* border: 1px solid #000; */
}
.img-container {
  width: 50%;
  height: 100%;
  position: relative;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.right-side {
  width: 50%;
  margin: auto;
  padding: 2rem;
  /* display:flex; */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 104, 175, 0);
  background: linear-gradient(to bottom, #003b7d, rgba(0, 145, 255, 0.4));
  z-index: 5;
}
.booking-text-login{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2rem;
  z-index: 15;
  text-align: center;
}
.bottom-text {
  position: absolute;
  width: 80%;
  top: 40%;
  left: 10%;
  color: #fff;
  font-size: 20px;
  z-index: 15;
  margin: auto;
  text-align: center;
}
@media (max-width: 1400px) {
  .login-container {
    width: 80%;
    height: 100vh;
    margin: auto;
    /* border: 1px solid #000; */
  }
  /* .booking-text {
    left: 30%;
  } */
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.sign-in-with {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}
.sign-in-with div {
  text-align: center;
  cursor: pointer;
}
@media (max-width: 1400px) {
    .img-container {
    display: none;
  }
  .sign-in-with {
    flex-direction: column;
    /* margin:auto; */
    align-items: center;
    gap: 20px;
  }
  .sign-in-with div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
}
</style>
