<template>
  <div
    class="room-container mb-4"
    :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
  >
    <div class="shadow mb-4">
      <div style="font-weight: 900" class="m-4 pt-3">
        {{ $t("message.chooseYourRoom") }}
        <span>({{ $t("message.selectBookingRooms") }})</span>
      </div>
      <div class="grid grid-cols-2 gap-5 p-5">
        <div
          v-for="(item, index) in hotelsInfo.room"
          :key="index"
          :class="{ selected: selectedRooms.find((s) => s.index === index) }"
          class="room-card"
        >
          <v-card>
            <HotelRoomComponent
              :roomCount="selectedRooms.find((s) => s.index === index)?.room_numbers || 1"
              @update:room-count="(v) => updateRoomNumber(index, v)"
              :hotelsData="item"
            />
            <div class="flex justify-end">
              <button
                v-if="selectedRooms.find((s) => s.index === index)"
                @click="toggleRoomSelection(index)"
                type="button"
                class="text-white bg-red-500 my-2 border focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
              >
                {{ $t("actions.deselect_room") }}
              </button>
              <button
                v-else
                @click="toggleRoomSelection(index)"
                type="button"
                class="text-white bg-[#003b7d] my-2 border focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
              >
                {{ $t("actions.select_room") }}
              </button>
              <button
                @click="dialogClick(index)"
                type="button"
                class="text-black my-2 mx-2 border focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
              >
                {{ $t("actions.bookAndViewRoom") }}
              </button>
            </div>
            <div v-if="selectedRooms.includes(index)" class="check-icon">
              <v-icon color="white">mdi-check</v-icon>
            </div>
          </v-card>
        </div>
      </div>
      <div v-if="selectedRooms.length" class="flex justify-end">
        <button
          @click="hotelClick()"
          type="button"
          class="text-white my-2 mx-2 bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
          v-if="checkin && checkout"
        >
          {{ $t("actions.bookNow") }}
        </button>
      </div>
    </div>
    <div class="text-center pa-4" style="font-family: 'Cairo', sans-serif">
      <div>
        <v-dialog v-model="dialog" :width="dialogWidth">
          <v-card>
            <v-card-title
              class="d-flex justify-space-between align-center"
              :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
            >
              <div style="color: orange; font-weight: 700">
                {{ $t("message.roomDetails") }}
              </div>
              <v-btn
                icon="mdi-close"
                variant="text"
                @click="dialog = false"
              ></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <div class="p-3 flex flex-col">
              <div class="w-full">
                <div class="HotelImage-container">
                  <v-carousel
                    v-model="currentIndex"
                    hide-delimiters
                    height="200px"
                  >
                    <template v-slot:prev>
                      <v-btn icon @click="prev" class="carousel-button">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:next>
                      <v-btn icon @click="next" class="carousel-button">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <v-carousel-item
                      v-for="(item, index) in hotelsInfoRoom.images"
                      :key="index"
                      :src="item.image_path || '@/assets/imgs/img.jpg'"
                    ></v-carousel-item>
                  </v-carousel>
                  <div class="image-count">
                    {{ currentIndex + 1 }} / {{ hotelsInfoRoom.images.length }}
                  </div>
                  <div class="thumbnails">
                    <div
                      v-for="(item, index) in hotelsInfoRoom.images"
                      :key="index"
                      class="thumbnail-wrapper"
                      :class="{ active: currentIndex === index }"
                      @click="currentIndex = index"
                    >
                      <img
                        :src="item.image_path || '@/assets/imgs/img.jpg'"
                        class="thumbnail"
                        :class="{ selected: currentIndex === index }"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="card-text"
                  :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
                >
                  <div class="header-text-container">
                    <div style="display: flex">
                      <h1 style="font-weight: 600; font-size: 26px">
                        {{ hotelsInfoRoom.name }}
                      </h1>
                    </div>
                  </div>
                  <div style="display: flex; gap: 10px">
                    <span style="color: #888" class="ml-1">
                      <v-icon
                        size="18"
                        class="text-#888"
                        icon="mdi-bed-queen-outline"
                      ></v-icon>
                      {{ hotelsInfoRoom.bathroom_number }}
                      {{ $t("message.bedroom") }}
                    </span>
                    <span style="color: #888">
                      <v-icon
                        size="18"
                        class="text-#888 ml-1"
                        icon="mdi-account-multiple-outline"
                      ></v-icon>
                      {{ $t("message.accommodates") }}
                      {{ hotelsInfoRoom.adult_number }}
                      {{ $t("message.persons") }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <div
                  class="main-container-services px-2 py-2"
                  :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
                >
                  <div>
                    <span style="font-size: 18px">{{
                      $t("message.services")
                    }}</span>
                  </div>
                  <div class="flex gap-2">
                    <template v-if="hotelsInfoRoom.services.length">
                      <div
                        v-for="(item, index) in hotelsInfoRoom.services"
                        :key="index"
                        class="service-item"
                      >
                        <i :class="item.icon" class="text-slate-600"></i>
                        <span style="font-size: 14px" class="mx-2">{{
                          item.ar_name
                        }}</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="no-services-message">
                        {{ $t("message.noAvailableServices") }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="room-price-section">
              <div class="room-price">
                <span
                  >{{ hotelsInfoRoom.total_price_with_currency }}
                  {{ $store.state.users.currency_selected }}
                </span>
                <span>{{ $t("message.includingTaxes") }}</span>
              </div>
              <div>
                <!-- <button
                  type="button"
                  class="ml-5 rounded-full text-sm px-8 py-2 bg-blue-800 text-white text-center hover:bg-blue-700 focus:outline-none"
                  @click="hotelClick()"
                >
                  {{ $t("actions.bookNow") }}
                </button> -->
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script setup>
import HotelRoomComponent from "./HotelRoomComponent.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted, computed } from "vue";
import { ref, defineProps, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});
const hotelsInfoRoom = ref({});
const dialog = ref(false);
const dialogWidth = ref("1250px");
const showHotelOpptions = ref(false);
const checkin = computed(() => route.query.checkin);
const checkout = computed(() => route.query.checkout);
const room_no = computed(() => route.query.room_no);
const adult_no = computed(() => route.query.adult_no);
const children_no = computed(() => route.query.children_no);
const governorate = computed(() => route.query.governorate);
const room_select = ref("");
const selectedRooms = ref( route.query.selectedRooms ? JSON.parse(route.query.selectedRooms) : []);
const currentIndex = ref(0);

const dialogClick = (index) => {
 dialog.value = true;
  hotelsInfoRoom.value = hotelsInfo.value.room[index];
  room_select.value = index;
};

const toggleRoomSelection = (index) => {
  const number = selectedRooms.value[index].room_numbers??1;
  const roomIndex = selectedRooms.value.findIndex((r) => r.index === index);
  if (roomIndex === -1) {
    selectedRooms.value.push({
      index,
      room_numbers: number
    });
  } else {
    selectedRooms.value = selectedRooms.value.filter((s) => s.index !== index);
  }
};

const updateRoomNumber = (index, number) => {
  if(!selectedRooms.value[index]){
    selectedRooms.value.push({
      index,
      room_numbers: number,
    });
  }else{
    selectedRooms.value[index].room_numbers = number;
  }
};

const hotelClick = () => {
  router.push({
    name: "HotelBooking",
    query: {
      id: route.query.id,
      governorate: governorate.value,
      checkin: checkin.value,
      checkout: checkout.value,
      room_no: room_no.value,
      room_select: JSON.stringify(selectedRooms.value),
      adult_no: adult_no.value,
      children_no: children_no.value,
      currency: store.getters.currency_selected,
    },
  });
};

const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
    showHotelOpptions.value = true;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const prev = () => {
  currentIndex.value =
    (currentIndex.value - 1 + hotelsInfoRoom.value.images.length) %
    hotelsInfoRoom.value.images.length;
};

const next = () => {
  currentIndex.value =
    (currentIndex.value + 1) % hotelsInfoRoom.value.images.length;
};

watch(
  () => props.hotelsData,
  () => {
    hotelsInfo.value = props.hotelsData;
  }
);

watch(
  selectedRooms,
  () => {
    router.push({
      query: {
        ...route.query,
        selectedRooms: JSON.stringify(selectedRooms.value),
      },
    });
  },
  {
    deep: true,
  }
);

onMounted(showHotel);
</script>
<style scoped>
.room-container {
  width: 90%;
  font-family: "Cairo", sans-serif;
  margin: auto;
  margin-top: 1.5rem;
}

.Hotelupper-container {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  margin: auto;
  gap: 15px;
  margin-top: 0.5rem;
}

.header-text-container {
  display: flex;
  justify-content: space-between;
}

.HotelImage-container {
  font-family: "Cairo", sans-serif;
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
}
.hotel-images {
  width: 60%;
}
.hotel-services {
  width: 40%;
}

.angry-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.angry-grid div img {
  border-radius: 10px;
}

.angry-grid {
  display: grid;
  gap: 10px;
  height: 100%;
}

#item-0 {
  grid-area: 1 / 1 / 4 / 5;
}
#item-1 {
  grid-area: 4 / 1 / 6 / 2;
}
#item-2 {
  grid-area: 4 / 2 / 6 / 3;
}
#item-3 {
  grid-area: 4 / 3 / 6 / 4;
}
#item-4 {
  grid-area: 4 / 4 / 6 / 5;
}

.selected {
  border: 2px solid blue;
  position: relative;
}

.room-card {
  position: relative;
}

.check-icon {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.room-details {
  display: flex;
  /* flex-direction: row; */
  gap: 20px;
  justify-content: space-between;
  padding: 1rem;
}

.room-price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.room-price {
  display: flex;
  flex-direction: column;
  color: #4347b6;
  font-weight: 700;
  font-size: 20px;
}
.no-services-message {
  text-align: center;
  padding: 5rem 1rem;
}

.carousel-button {
  background: none;
  box-shadow: none;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-weight: bold;
}

.dialog-content {
  padding: 16px;
  text-align: center;
}

.image-count {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  color: #777;
}

.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  overflow-x: hidden;
  width: auto;
}

.thumbnail-wrapper {
  padding: 5px;
  opacity: 0.5;
  width: auto;
}

.thumbnail-wrapper.active {
  opacity: 1;
}

.thumbnail-wrapper.active .thumbnail {
  border: 2px solid #ff9800;
}

.thumbnail {
  width: 60px;
  height: 60px;
  margin: 0 5px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s;
  object-fit: cover;
}

.thumbnail.selected {
  border: 2px solid #ff9800;
}

.thumbnail:hover {
  border: 2px solid #ff9800;
}

/* Responsive Design */
@media (max-width: 767px) {
  .room-container {
    width: 95%;
  }
  .room-details {
    flex-direction: column-reverse;
  }
  .hotel-images {
    width: 100%;
  }
  .hotel-services {
    width: 100%;
  }
  .Hotelupper-container {
    justify-content: center;
  }
  .angry-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
  }
  .room-price-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .room-price {
    font-size: 18px;
  }
  .v-dialog {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .room-container {
    width: 85%;
  }
  .room-details {
    flex-direction: column;
  }
  .v-dialog {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .room-container {
    width: 70%;
  }
  .v-dialog {
    width: 60%;
  }
}
</style>
